define(['lodash', 'experiment'], function (_, experiment) {
    'use strict';
    const minInputHeight = 25;
    const defaultInputHeight = 42;
    const defaultLabelMargin = 14;
    const paddingLimit = '20px';
    const maxLabelFontSizeMobileView = 16;
    const useMobileHeight = isMobileView => isMobileView && experiment.isOpen('sv_inputElementsLabelMobileHooks');//eslint-disable-line no-restricted-syntax
    
    return {
        minInputHeight,
        defaultInputHeight,
        measureComponentWithLabel(compProp, label, compData, isMobileView) {
            const {inputHeight, inputHeightMobile, labelMargin} = compProp;
            const heightToUse = useMobileHeight(isMobileView) && inputHeightMobile ? inputHeightMobile : inputHeight;

            let height;
            if (this.hasLabel(compData)) {
                const labelHeight = (_.isNumber(labelMargin) ? labelMargin : defaultLabelMargin) + label.offsetHeight;
                const newHeight = (heightToUse || defaultInputHeight) + labelHeight;
                height = Math.max(minInputHeight + labelHeight, newHeight);
            } else if (heightToUse) {
                height = Math.max(minInputHeight, heightToUse);
            }
            return height;
        },
        getLabelPadding(compProp) {
            const {textAlignment, labelPadding, filesAlignment} = compProp;
            const alignment = textAlignment || filesAlignment;

            switch (alignment) {
                case 'left':
                    return {paddingLeft: labelPadding, paddingRight: paddingLimit};
                case 'right':
                    return {paddingRight: labelPadding, paddingLeft: paddingLimit};
                default:
                    return {};
            }
        },
        getLabelFntStyle(fontStyle, isMobileView) {
            if (isMobileView) {
                const fntSize = fontStyle.fontSize ? Number(fontStyle.fontSize.replace('px', '')) : 0;
                const size = _.max([maxLabelFontSizeMobileView, fntSize]);
                fontStyle.fontSize = `${size}px`;
            }
            return fontStyle;
        },
        getLabelStyle(compProp, rawFontSyle, isMobileView, compData) {
            const {textAlignment, labelMargin, filesAlignment} = compProp;
            const padding = this.getLabelPadding(compProp);
            const fontStyle = this.getLabelFntStyle(rawFontSyle, isMobileView);
            const alignment = textAlignment || filesAlignment;

            return _.merge(padding, fontStyle, {
                display: this.hasLabel(compData) ? 'inline-block' : 'none',
                marginBottom: _.isNumber(labelMargin) ? labelMargin : defaultLabelMargin,
                textAlign: alignment,
                direction: alignment === 'right' ? 'rtl' : 'ltr'
            });
        },
        hasLabel(compData) {
            return !!compData.label;
        },
        showRequired(compData, compProp, compTheme) {
            const requiredStyle = _.get(compTheme, 'style.properties.txtlblrq');
            const hasRequiredStyle = requiredStyle && requiredStyle !== 'transparent';
            return this.hasLabel(compData) && compProp.required && hasRequiredStyle;
        },
        getBaseInputWithLabelSkinProperties({compProp, rawFontSyle, isMobileView, compData, id: {compId, inputId}}) {
            return {
                label: {children: compData.label, style: this.getLabelStyle(compProp, rawFontSyle, isMobileView, compData), htmlFor: `${compId}${inputId}`}
            };
        }
    };
});
